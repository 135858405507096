import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
import { Bookmark } from '../app.state';

import { MailService } from '../mail.service';

@Component({
  selector: 'app-gallery-download-pdf',
  templateUrl: './gallery-download-pdf.component.html',
  styleUrls: ['./gallery-download-pdf.component.scss'],
})
export class GalleryDownloadPdfComponent {

  public form: FormGroup;

  public bookmarks: Bookmark[];

  public isLoading = false;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private mailService: MailService, public modal: NgbActiveModal) {
    this.form = this.formBuilder.group({
      name: [this.activatedRoute.snapshot.queryParams.user_name, [Validators.required]],
      title: [this.activatedRoute.snapshot.queryParams.user_title, [Validators.required]],
      company: [this.activatedRoute.snapshot.queryParams.user_company, [Validators.required]],
      email: [this.activatedRoute.snapshot.queryParams.user_email, [Validators.required, Validators.email]]
    });
  }

  public async download(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;

    const blob = await this.generatePDF();

    await this.mailService.sendMail(this.form.value.name, this.form.value.title, this.form.value.company, this.form.value.email, blob);

    this.isLoading = false;
    this.modal.close();

    window.open(URL.createObjectURL(blob));
  }

  private async generatePDF(): Promise<Blob> {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();

    const summaryDiv = document.getElementById('summary');
    const summaryDivWidth = summaryDiv.offsetWidth;

    await doc.html(document.getElementById('summary'), {
      x: 0,
      y: 0,
      fontFaces: [
        {
          family: 'NoeDisplay',
          style: 'normal',
          weight: 'normal',
          src: [
            {
              format: 'truetype',
              url: 'assets/fonts/Noe-Display-Bold.ttf'
            }
          ]
        },
        {
          family: 'RivieraNights',
          style: 'normal',
          weight: 'normal',
          src: [
            {
              format: 'truetype',
              url: 'assets/fonts/Riviera-Nights-Regular.ttf'
            }
          ]
        }
      ],
      html2canvas: {
        scale: pageWidth / summaryDivWidth
      }
    });

    const pageCount = doc.getNumberOfPages();
    doc.deletePage(pageCount);

    return doc.output('blob');
  }

}
