import { Pipe, PipeTransform } from '@angular/core';

import { Enrichment, Material } from './catalog.interface';

@Pipe({
  name: 'enrichment',
  pure: false
})
export class EnrichmentPipe implements PipeTransform {

  transform(materials: Material[], enrichment: Enrichment): Material[] {
    return materials.filter(m => !!m.enrichments.find(e => e.id === enrichment.id));
  }

}
