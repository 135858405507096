import { Component, OnDestroy, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Bookmark } from '../app.state';

import { AppStoreService } from '../app.store.service';
import { GalleryDownloadPdfComponent } from '../gallery-download-pdf/gallery-download-pdf.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnDestroy, OnInit {

  public bookmarks: Bookmark[];

  public selectedBookmark: Bookmark;

  private subscription: Subscription;

  constructor(private readonly appStoreService: AppStoreService, public modal: NgbActiveModal, private modalService: NgbModal) {
  }

  public ngOnInit(): void {
    this.subscription = this.appStoreService.state$.subscribe(state => {
      if (this.bookmarks && !state.bookmarks.find(b => b === this.selectedBookmark)) {
        const index = this.bookmarks.findIndex(b => b === this.selectedBookmark);
        this.selectedBookmark = state.bookmarks[this.mod(index, this.bookmarks.length)];
      }

      this.bookmarks = state.bookmarks;

      if (!this.selectedBookmark) {
        this.selectedBookmark = this.bookmarks[0];
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public previousBookmark(): void {
    if (this.bookmarks.length < 2) {
      return;
    }

    const index = this.bookmarks.findIndex(b => b === this.selectedBookmark);
    this.selectedBookmark = this.bookmarks[this.mod(index - 1, this.bookmarks.length)];
  }

  public nextBookmark(): void {
    if (this.bookmarks.length < 2) {
      return;
    }

    const index = this.bookmarks.findIndex(b => b === this.selectedBookmark);
    this.selectedBookmark = this.bookmarks[this.mod(index + 1, this.bookmarks.length)];
  }

  public selectBookmark(bookmark: Bookmark): void {
    this.selectedBookmark = bookmark;
  }

  public viewSelectedBookmark(): void {
    this.appStoreService.selectDesign(this.selectedBookmark);
    this.modal.close();
  }

  public deleteSelectedBookmark(): void {
    this.appStoreService.deleteBookmark(this.selectedBookmark.id);
  }

  public async downloadPDF(): Promise<void> {
    this.modal.close();

    const modal = this.modalService.open(GalleryDownloadPdfComponent, { centered: true, size: 'lg' });
    modal.componentInstance.bookmarks = this.bookmarks;
  }

  private mod(x: number, y: number): number {
    return (x - (y * Math.floor(x / y)));
  }
}
