import { Frame, Color, Grain, Material, Section, Shape, Seam } from './catalog.interface';

export class AppState {
  interactionHelper: boolean;

  design: Design;
  scale: boolean;
  selectedSectionId: string;

  bookmarks: Bookmark[];

  constructor() {
    this.interactionHelper = true;

    this.design = null;
    this.scale = false;
    this.selectedSectionId = null;

    this.bookmarks = [];
  }
}

export interface Design extends Shape {
  snapshot: string;
  sections: SectionConfiguration[];
  frame: Frame;
  seam: Seam;
  pipingColor: Color;
  stitchColor: Color;
}

export type Bookmark = Design;

export interface SectionConfiguration extends Section {
  snapshot: string;
  grain: Grain;
  color: Color;
  material: Material;
  materialCrustColor?: Color;
  materialStitchColor?: Color;
}
