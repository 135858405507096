/// <reference types="gtag.js" />

import { Injectable } from '@angular/core';

export interface GTagEvent {
  type: string;
  category: string;
  label: string;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  addEvent(type: string, category: string, label: string): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    gtag('event', type, { event_category: category, event_label: label });
  }
}
