import { Injectable } from '@angular/core';
import { WRAPIv2 } from '@lumiscaphe/viewer';
import { nanoid } from 'nanoid';

import { AppState, Design, SectionConfiguration } from './app.state';
import { Frame, Color, Grain, Material, Shape, Seam } from './catalog.interface';
import { Store } from './store';

import { StatsService } from './stats.service';
import { WebRenderService } from './web-render.service';

import catalog from '../assets/catalog.json';

@Injectable()
export class AppStoreService extends Store<AppState> {
  private storeKey = 'pangea.state.v5';

  constructor(private webRenderService: WebRenderService, private statsService: StatsService) {
    super(new AppState());

    this.resetDesign();

    try {
      const storeItem = sessionStorage.getItem(this.storeKey);

      if (storeItem) {
        this.state = JSON.parse(storeItem);
      }
    } catch {
      //
    }

    this.state$.subscribe((appState: AppState) => {
      try {
        sessionStorage.setItem(this.storeKey, JSON.stringify(appState));
      } catch {
        //
      }
    });
  }

  // Design

  selectDesign(design: Design): void {
    const sections = design.sections.map(s => ({ ...s }));

    this.state = {
      ...this.state,
      design: {
        ...design,
        sections
      }
    };
  }

  resetDesign(): void {
    const shape = catalog.shapes.find(s => s.id === catalog.defaultShape);

    const defaultFrame = catalog.frames.find(b => b.id === catalog.defaultFrame);
    const defaultSeam = catalog.seams.find(b => b.id === catalog.defaultSeam);
    const defaultPipingColor = catalog.pipingColors.find(c => c.id === catalog.defaultPipingColor);
    const defaultStitchColor = catalog.stitchColors.find(c => c.id === catalog.defaultStitchColor);

    const defaultGrain = catalog.grains.find(g => g.id === catalog.defaultGrain);
    const defaultColor = catalog.colors.find(c => c.id === catalog.defaultColor);
    const defaultMaterial = catalog.materials.find(m => m.id === catalog.defaultMaterial);

    const sections = shape.sections.map(s => ({
      ...s,
      snapshot: null,
      grain: defaultGrain,
      color: defaultColor,
      material: defaultMaterial
    }));

    this.state = {
      ...this.state,
      design: {
        ...shape,
        snapshot: null,
        frame: defaultFrame,
        seam: defaultSeam,
        pipingColor: defaultPipingColor,
        stitchColor: defaultStitchColor,
        sections
      },
      selectedSectionId: sections[0].id
    };
  }

  // View

  toggleZoom(): void {
    this.state = {
      ...this.state
    };
  }

  // Shape

  isShapeSelected(id: string): boolean {
    return this.state.design?.id === id;
  }

  setShape(shape: Shape): void {
    if (!this.isShapeSelected(shape.id)) {
      this.statsService.addEvent('click', 'Shape', shape.name);
    }

    const defaultFrame = catalog.frames.find(b => b.id === catalog.defaultFrame);
    const frame = this.state?.design?.frame || defaultFrame;
    const defaultSeam = catalog.seams.find(b => b.id === catalog.defaultSeam);
    const seam = this.state?.design?.seam || defaultSeam;
    const defaultPipingColor = catalog.pipingColors.find(c => c.id === catalog.defaultPipingColor);
    const pipingColor = this.state?.design?.pipingColor || defaultPipingColor;
    const defaultStitchColor = catalog.stitchColors.find(c => c.id === catalog.defaultStitchColor);
    const stitchColor = this.state?.design?.stitchColor || defaultStitchColor;

    const defaultGrain = catalog.grains.find(g => g.id === catalog.defaultGrain);
    const defaultColor = catalog.colors.find(c => c.id === catalog.defaultColor);
    const defaultMaterial = catalog.materials.find(m => m.id === catalog.defaultMaterial);

    const sections = shape.sections.map((s, i) => {
      const previousSection = this.state.design?.sections[i];

      return {
        ...s,
        snapshot: null,
        grain: previousSection?.grain || defaultGrain,
        color: previousSection?.color || defaultColor,
        material: previousSection?.material || defaultMaterial
      };
    });

    this.state = {
      ...this.state,
      design: {
        ...shape,
        snapshot: null,
        frame,
        seam,
        pipingColor,
        stitchColor,
        sections
      },
      selectedSectionId: sections[0].id
    };
  }

  // Section

  isSectionAvailable(): boolean {
    return this.state.design?.sections.length > 1;
  }

  isSectionSelected(sectionId: string): boolean {
    return this.state.selectedSectionId === sectionId;
  }

  selectSection(sectionId: string): void {
    this.state = {
      ...this.state,
      selectedSectionId: sectionId
    };
  }

  setSection(sectionId: string, data: Partial<SectionConfiguration>): void {
    const sections = this.state.design.sections.map(s => {
      if (s.id !== sectionId) {
        return s;
      }

      return {
        ...s,
        ...data
      };
    });

    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        sections
      }
    };

    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        sections
      }
    };
  }

  // Frame

  isFrameSelected(frameId: string): boolean {
    return this.state.design?.frame.id === frameId;
  }

  setFrame(frame: Frame): void {
    if (!this.isFrameSelected(frame.id)) {
      this.statsService.addEvent('click', 'Frame', frame.name);
    }

    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        frame
      }
    };
  }

  // Seam

  isSeamAvailable(): boolean {
    return this.state.design?.sections.length > 1;
  }

  isSeamSelected(seamId: string): boolean {
    return this.state.design?.seam.id === seamId;
  }

  setSeam(seam: Seam): void {
    if (!this.isSeamSelected(seam.id)) {
      this.statsService.addEvent('click', 'Seam', seam.name);
    }

    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        seam
      }
    };
  }

  // Pipes Color

  isPipingColorAvailable(): boolean {
    return this.isSeamAvailable() && this.state.design?.seam.id === 'piping';
  }

  isPipingColorSelected(colorId: string): boolean {
    return this.state.design?.pipingColor.id === colorId;
  }

  selectPipingColor(color: Color): void {
    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        pipingColor: color
      }
    };
  }

  // Stitches Color

  isStitchesColorAvailable(): boolean {
    return this.isSeamAvailable() && this.state.design?.seam.id === 'stiching';
  }

  isStitchesColorSelected(colorId: string): boolean {
    return this.state.design.stitchColor.id === colorId;
  }

  selectStitchesColor(color: Color): void {
    this.state = {
      ...this.state,
      design: {
        ...this.state.design,
        stitchColor: color
      }
    };
  }

  // Grain

  isGrainSelected(sectionId: string, grainId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.grain.id === grainId;
  }

  selectGrain(sectionId: string, grain: Grain): void {
    if (!this.isGrainSelected(sectionId, grain.id)) {
      this.statsService.addEvent('click', 'Grain', grain.name);
    }

    this.setSection(sectionId, { grain });
  }

  // Color

  isColorSelected(sectionId: string, colorId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.color.id === colorId;
  }

  selectColor(sectionId: string, color: Color): void {
    if (!this.isColorSelected(sectionId, color.id)) {
      this.statsService.addEvent('click', 'Color', color.name);
    }

    this.setSection(sectionId, { color });
  }

  // Material

  isMaterialSelected(sectionId: string, materialId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.material.id === materialId;
  }

  setMaterial(sectionId: string, material: Material): void {
    if (!this.isMaterialSelected(sectionId, material.id)) {
      this.statsService.addEvent('click', 'Material', material.name);
    }

    this.setSection(sectionId, { material, materialCrustColor: undefined, materialStitchColor: undefined });
  }

  // Material Crust Color

  isMaterialCrustColorAvailable(sectionId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.material?.crustColorLayer !== undefined;
  }

  isMaterialCrustColorSelected(sectionId: string, colorId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.materialCrustColor?.id === colorId;
  }

  selectMaterialCrustColor(sectionId: string, materialCrustColor: Color): void {
    if (!this.isMaterialCrustColorSelected(sectionId, materialCrustColor.id)) {
      this.statsService.addEvent('click', 'Crust Color', materialCrustColor.name);
    }

    this.setSection(sectionId, { materialCrustColor });
  }

  isMaterialCrustColorDisable(sectionId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.materialCrustColor === undefined;
  }

  disableMaterialCrustColor(sectionId: string): void {
    this.setSection(sectionId, { materialCrustColor: undefined });
  }

  // Material Stitch Color

  isMaterialStitchColorAvailable(sectionId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.material?.stitchColorLayer !== undefined;
  }

  isMaterialStitchColorSelected(sectionId: string, colorId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.materialStitchColor?.id === colorId;
  }

  selectMaterialStitchColor(sectionId: string, materialStitchColor: Color): void {
    if (!this.isMaterialStitchColorSelected(sectionId, materialStitchColor.id)) {
      this.statsService.addEvent('click', 'Stitch Color', materialStitchColor.name);
    }

    this.setSection(sectionId, { materialStitchColor });
  }

  isMaterialStitchColorDisable(sectionId: string): boolean {
    return this.state.design.sections.find(s => s.id === sectionId)?.materialStitchColor === undefined;
  }

  disableMaterialStitchColor(sectionId: string): void {
    this.setSection(sectionId, { materialStitchColor: undefined });
  }

  // Material

  getMaterialNameBySection(sectionId: string): string {
    const section = this.state.design.sections.find(s => s.id === sectionId);

    return `${section.material.name}_${section.color.id}_${section.grain.id}`;
  }

  // Gallery

  async createBookmark(): Promise<void> {
    const design: Design = {
      ...this.state.design,
      id: nanoid(),
      snapshot: await this.snapshotDesign(this.state.design, catalog.views[0].camera, 1280, 720),
      sections: this.state.design.sections.map(s => ({ ...s }))
    };

    await Promise.all(design.sections.map(s => this.snapshotDesign(design, catalog.views.find(v => v.id === s.view).camera, 1280, 720).then(url => { s.snapshot = url; return url; })));

    this.state = {
      ...this.state,
      bookmarks: [...this.state.bookmarks, design]
    };
  }

  deleteBookmark(bookmarkId: string): void {
    const bookmarks = this.state.bookmarks.filter(b => b.id !== bookmarkId);

    this.state = {
      ...this.state,
      bookmarks
    };
  }

  async snapshotDesign(design: Design, camera: string, width = 320, height = 180): Promise<string> {
    const snapshot: WRAPIv2.Snapshot = {
      scene: this.getScene(design, false),
      mode: {
        image: {
          camera
        }
      },
      renderParameters: {
        overlay: false,
        superSampling: '2',
        width,
        height
      },
      encoder: {
        jpeg: {
          quality: 80
        }
      }
    };
    return (await this.webRenderService.snapshot(snapshot).toPromise() as WRAPIv2.Frame).url;
  }

  // Scene

  getScene(design?: Design, scale = true): WRAPIv2.Scene {
    const materialDefines = this.state.design.sections.filter(s => s.material.define).map(s => `${s.parameter}.${s.material.define}`.replace('%GRAIN%', s.grain.name));

    const materialMultiLayers: WRAPIv2.MaterialMultiLayerArray = [];
    const materialSeams: WRAPIv2.MaterialSeamArray = [];
    const materialStandards: WRAPIv2.MaterialStandardArray = [];

    materialSeams.push({
      name: 'Tan Stitch',
      diffuseColor: this.state.design.stitchColor.value
    });

    if (this.isPipingColorAvailable()) {
      materialStandards.push({
        name: 'GSTA Generic Napa Leather',
        diffuseColor: this.state.design.pipingColor.value
      });
    }

    for (const section of this.state.design.sections) {
      if (!section.material.materialName) {
        continue;
      }

      const materialName = section.material.materialName.replace('%GRAIN%', section.grain.name.toUpperCase()).replace('%SECTION%', section.id);

      if (section.material.colorLayer === undefined) {
        materialStandards.push({
          name: materialName,
          diffuseColor: section.color.value
        });
      } else {
        materialMultiLayers.push({
          name: materialName,
          layer: section.material.colorLayer,
          diffuseColor: section.color.value
        });

        if (section.material.crustColorLayer !== undefined) {
          materialMultiLayers.push({
            name: materialName,
            layer: section.material.crustColorLayer,
            diffuseColor: section.materialCrustColor?.darkValue || section.color.darkValue
          });
        }

        if (section.material.stitchColorLayer !== undefined) {
          materialMultiLayers.push({
            name: materialName,
            layer: section.material.stitchColorLayer,
            diffuseColor: section.materialStitchColor?.darkValue || section.color.darkValue
          });
        }
      }
    }

    const defines = [
      (design || this.state.design).define,
      (design || this.state.design).frame.define,
      (design || this.state.design).seam.define,
      ...materialDefines
    ];

    if (scale && this.state.scale) {
      defines.push('Mesureing tape.ON');
      defines.push('Scale.ON');
    }

    return {
      database: catalog.id,
      configuration: defines.join('/'),
      materialMultiLayers: materialMultiLayers.length ? materialMultiLayers : undefined,
      materialSeams: materialSeams.length ? materialSeams : undefined,
      materialStandards: materialStandards.length ? materialStandards : undefined
    };
  }

  // Scale

  setScale(value: boolean): void {
    this.state = {
      ...this.state,
      scale: value
    };
  }

  // Interaction Helper

  setInteractionHelper(value: boolean): void {
    this.state = {
      ...this.state,
      interactionHelper: value
    };
  }
}
