export const environment = {
  production: true,
  webrender: {
    server: 'https://wr-pangea.lumiscaphe.com',
    parameters: {
      overlay: false,
      antialiasing: false,
      superSampling: 2
    },
    encoder: {
      format: 'jpeg',
      quality: 80
    }
  }
};
