<nav class="navbar navbar-dark">
  <a class="navbar-brand" href="#">
    <img src="assets/images/logo.png" width="30" height="30" class="d-inline-block align-top">
  </a>
  <div class="form-inline">
    <button type="button" class="btn btn-link mx-4" (click)="resetDesign()">
      <i class="fal fa-sync fa-lg mr-3"></i>
      <span class="d-none d-sm-inline">Reset</span>
    </button>
    <button type="button" class="btn btn-link mx-4" (click)="bookmarkDesign()">
      <i class="fal fa-bookmark fa-lg mr-3"></i>
      <span class="d-none d-sm-inline">Bookmark</span>
    </button>
    <button type="button" class="btn btn-link mx-4" (click)="openDesignGallery()">
      <i class="fal fa-images fa-lg mr-3"></i>
      <span class="d-none d-sm-inline">Gallery</span>
    </button>
  </div>
</nav>

<div class="row flex-grow-1 flex-md-nowrap no-gutters">
  <div class="d-flex col-sm-12 col-md-3">
    <div class="flex-grow-1 d-flex flex-column">
      <div id="shapeAccordion" class="d-flex flex-column overflow-auto px-3">
        <h4 class="border-bottom border-primary m-3 py-2 text-center">Shape</h4>
        <div>
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let shape of catalog.shapes">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isShapeSelected(shape)" (click)="selectShape(shape)">
                  <img [src]="shape.thumbnail" class="p-1 img-fluid" />
                  <small>{{shape.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <h4 class="border-bottom border-primary m-3 py-2 text-center">Section</h4>
        <div>
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let section of design.sections">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isSectionSelected(section)" (click)="selectSection(section)">
                  <img [src]="section.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{section.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseFrames" data-toggle="collapse">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Frame</h4>
        </a>
        <div id="collapseFrames" class="collapse show" data-parent="#shapeAccordion">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let frame of catalog.frames">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isFrameSelected(frame)" (click)="selectFrame(frame)">
                  <img [src]="frame.thumbnail" class="p-1 img-fluid" />
                  <small>{{frame.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseSeams" data-toggle="collapse" *ngIf="isSeamAvailable()">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Seams</h4>
        </a>
        <div id="collapseSeams" class="collapse" data-parent="#shapeAccordion" *ngIf="isSeamAvailable()">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let seam of catalog.seams">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isSeamSelected(seam)" (click)="selectSeam(seam)">
                  <img [src]="seam.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{seam.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseStitchesColor" data-toggle="collapse">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Stitch Color</h4>
        </a>
        <div id="collapseStitchesColor" class="collapse" data-parent="#shapeAccordion">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let color of catalog.stitchColors">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isStitchesColorSelected(color)" (click)="selectStitchesColor(color)">
                  <img [src]="color.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{color.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapsePipingColor" data-toggle="collapse" *ngIf="isPipingColorAvailable()">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Piping Color</h4>
        </a>
        <div id="collapsePipingColor" class="collapse" data-parent="#shapeAccordion" *ngIf="isPipingColorAvailable()">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let color of catalog.pipingColors">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isPipingColorSelected(color)" (click)="selectPipingColor(color)">
                  <img [src]="color.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{color.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column col-sm-12 col-md-6" style="min-height: calc(var(--vh, 1vh) * 40);">
    <app-viewer></app-viewer>
  </div>

  <div class="d-flex col-sm-12 col-md-3">
    <div class="flex-grow-1 d-flex flex-column">
      <div id="sectionAccordion" class="d-flex flex-column px-3">
        <a href="#" class="btn-link" data-target="#collapseGrains" data-toggle="collapse">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Grain</h4>
        </a>
        <div id="collapseGrains" class="collapse show" data-parent="#sectionAccordion">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let grain of catalog.grains">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isGrainSelected(grain)" (click)="selectGrain(grain)">
                  <img [src]="grain.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{grain.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseColors" data-toggle="collapse">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Color</h4>
        </a>
        <div id="collapseColors" class="collapse" data-parent="#sectionAccordion">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let color of catalog.colors">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isColorSelected(color)" (click)="selectColor(color)">
                  <img [src]="color.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{color.name}}</small>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseEnrichments" data-toggle="collapse">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Enrichment</h4>
        </a>
        <div id="collapseEnrichments" class="collapse overflow-auto" data-parent="#sectionAccordion">
          <div class="d-flex flex-column overflow-auto px-3">
            <div class="row no-gutters justify-content-center">
              <ng-container *ngFor="let enrichment of catalog.enrichments">
                <h5 class="border-bottom m-2 p-2 w-100">{{enrichment.name}}</h5>
                <ng-container *ngFor="let material of catalog.materials | enrichment:enrichment">
                  <div class="col-12 d-flex justify-content-center">
                    <button class="btn btn-link btn-link-material d-flex flex-row align-items-stretch mb-1" [class.active]="isMaterialSelected(material)" (click)="setMaterial(material)">
                      <div style="flex-basis: 25%;">
                        <img [src]="material.thumbnail" class="m-1 img-fluid" />
                      </div>
                      <div class="flex-grow-1 d-flex flex-column justify-content-between py-4">
                        <h5>{{material.name}}</h5>
                        <div class="d-flex flex-row justify-content-center">
                          <span class="font-weight-normal mx-2" *ngFor="let enrichment of material.enrichments">{{enrichment.name}}</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseMaterialCrustColor" data-toggle="collapse" *ngIf="isMaterialCrustColorAvailable()">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Crust Color</h4>
        </a>
        <div id="collapseMaterialCrustColor" class="collapse" data-parent="#sectionAccordion" *ngIf="isMaterialCrustColorAvailable()">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let color of catalog.stitchColors">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isMaterialCrustColorSelected(color)" (click)="selectMaterialCrustColor(color)">
                  <img [src]="color.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{color.name}}</small>
                </button>
              </div>
            </ng-container>
            <div class="col-3 d-flex justify-content-center">
              <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isMaterialCrustColorDisable()" (click)="disableMaterialCrustColor()">
                <img src="assets/images/none.png" class="m-1 p-1 img-fluid" />
                <small>None</small>
              </button>
            </div>
          </div>
        </div>
        <a href="#" class="btn-link" data-target="#collapseMaterialStitchColor" data-toggle="collapse" *ngIf="isMaterialStitchColorAvailable()">
          <h4 class="border-bottom border-primary m-3 py-2 text-center">Stitch Color</h4>
        </a>
        <div id="collapseMaterialStitchColor" class="collapse" data-parent="#sectionAccordion" *ngIf="isMaterialStitchColorAvailable()">
          <div class="row no-gutters justify-content-center">
            <ng-container *ngFor="let color of catalog.stitchColors">
              <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isMaterialStitchColorSelected(color)" (click)="selectMaterialStitchColor(color)">
                  <img [src]="color.thumbnail" class="m-1 p-1 img-fluid" />
                  <small>{{color.name}}</small>
                </button>
              </div>
            </ng-container>
            <div class="col-3 d-flex justify-content-center">
              <button class="btn btn-link d-flex flex-column align-items-center mb-1" [class.active]="isMaterialStitchColorDisable()" (click)="disableMaterialStitchColor()">
                <img src="assets/images/none.png" class="m-1 p-1 img-fluid" />
                <small>None</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>