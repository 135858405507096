import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NgViewerModule } from '@lumiscaphe/ng-viewer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryDownloadPdfComponent } from './gallery-download-pdf/gallery-download-pdf.component';
import { ViewerComponent } from './viewer/viewer.component';

import { EnrichmentPipe } from './enrichment.pipe';

import { AppStoreService } from './app.store.service';
import { WebRenderService } from './web-render.service';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    EnrichmentPipe,
    GalleryComponent,
    GalleryDownloadPdfComponent,
    ViewerComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgViewerModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    AppStoreService,
    WebRenderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
