<div class="modal-header">
  <h5 class="modal-title">Design gallery</h5>
  <button type="button" class="close" (click)="modal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column align-items-center">
    <ng-container *ngIf="bookmarks.length !== 0">
      <div class="position-relative" *ngIf="selectedBookmark">
        <img class="img-fluid" src="{{selectedBookmark.snapshot}}">
        <a class="carousel-control-prev" href="#carousel" (click)="previousBookmark()">
          <span class="carousel-control-prev-icon"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel" (click)="nextBookmark()">
          <span class="carousel-control-next-icon"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <ol class="carousel-indicators">
        <li [class.active]="bookmark === selectedBookmark" (click)="selectBookmark(bookmark)" *ngFor="let bookmark of bookmarks">
          <img class="img-fluid" src="{{bookmark.snapshot}}">
        </li>
      </ol>
      <hr />
      <ng-container *ngIf="selectedBookmark">
        <table class="table-borderless text-center w-100">
          <colgroup>
            <col span="1" style="width: 19%">
            <col span="1" style="width: 27%">
            <col span="1" style="width: 27%">
            <col span="1" style="width: 27%">
          </colgroup>
          <thead class="border-bottom">
            <tr>
              <th class="p-2">Section</th>
              <th class="p-2">Grain</th>
              <th class="p-2">Color</th>
              <th class="p-2">Enrichment</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let section of selectedBookmark.sections">
              <td class="p-2">{{section.name}}</td>
              <td class="p-2">{{section.grain.name}}</td>
              <td class="p-2">{{section.color.name}}</td>
              <td class="p-2">{{section.material.name}}</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex align-items-center justify-content-center mt-3">
          <button type="button" class="btn btn-light mx-3" [disabled]="!selectedBookmark" data-dismiss="modal" (click)="viewSelectedBookmark()">
            <i class="fal fa-eye fa-lg mr-3"></i>
            <span>Check it out</span>
          </button>
          <button type="button" class="btn btn-light mx-3" [disabled]="!selectedBookmark" (click)="deleteSelectedBookmark()">
            <i class="fal fa-trash fa-lg mr-3"></i>
            <span>Delete</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="bookmarks.length === 0">
      <div class="text-center">
        No bookmarks yet
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer flex-column align-items-stretch">
  <button type="button" class="btn btn-light mx-3" data-dismiss="modal" [disabled]="bookmarks.length === 0" (click)="downloadPDF()">
    <i class="fal fa-file-pdf fa-lg mr-3"></i>
    <span>Download gallery PDF</span>
  </button>
</div>