<div class="modal-header">
  <h5 class="modal-title">Download your gallery</h5>
  <button type="button" class="close" (click)="modal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" formControlName="name" required>
      </div>
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" class="form-control" id="title" formControlName="title" required>
      </div>
      <div class="form-group">
        <label for="company">Company</label>
        <input type="text" class="form-control" id="company" formControlName="company" required>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" formControlName="email" required>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer flex-column align-items-stretch">
  <button type="submit" class="btn btn-light mx-3" data-dismiss="modal" [disabled]="!form.valid || isLoading" (click)="download()">
    <i *ngIf="isLoading" class="fal fa-spinner fa-spin fa-lg mr-3"></i>
    <span *ngIf="!isLoading">Download</span>
  </button>
</div>

<div class="container position-fixed invisible w-100" style="bottom: -100vh;">
  <div id="summary" class="d-flex flex-column summary">
    <div class="d-flex flex-column justify-content-between bg-white page" *ngFor="let bookmark of bookmarks">
      <div class="flex-shrink-0">
        <img class="m-3" src="assets/images/logo.png" width="30" height="30">
      </div>
      <img class="img-fluid" [src]="bookmark.snapshot">
      <div class="flex-grow-1 d-flex flex-column align-items-stretch justify-content-center">
        <div class="flex-grow-1 overflow-hidden p-1" *ngFor="let section of bookmark.sections">
          <img class="w-100 h-100" style="object-fit: cover;" src="{{section.snapshot}}">
        </div>
      </div>
      <table class="table-borderless text-center mb-2 w-100">
        <colgroup>
          <col span="1" style="width: 19%">
          <col span="1" style="width: 27%">
          <col span="1" style="width: 27%">
          <col span="1" style="width: 27%">
        </colgroup>
        <thead class="border-bottom">
          <tr>
            <th class="p-2">Section</th>
            <th class="p-2">Grain</th>
            <th class="p-2">Color</th>
            <th class="p-2">Enrichment</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let section of bookmark.sections">
            <td class="p-2">{{section.name}}</td>
            <td class="p-2">{{section.grain.name}}</td>
            <td class="p-2">{{section.color.name}}</td>
            <td class="p-2">{{section.material.name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>