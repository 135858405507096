import { Component, OnDestroy, OnInit } from '@angular/core';
import { Encoder, Parameters, WidgetVRObject } from '@lumiscaphe/viewer';
import { Subscription } from 'rxjs';

import { View } from '../catalog.interface';

import { AppStoreService } from '../app.store.service';

import { environment } from 'src/environments/environment';

import catalog from '../../assets/catalog.json';
import { Design } from '../app.state';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnDestroy, OnInit {
  public server: string;
  public scene: any;
  public view: View;
  public parameters: Parameters;
  public encoder: Encoder;
  public vrobject: Partial<WidgetVRObject>;

  public error: boolean;
  public progress: number;
  public loading: boolean;

  public interactionHelper: boolean;

  public design: Design;
  public selectedSectionId: string;

  public scale: boolean;

  public zoomOutAvailable: boolean;
  public zoomInAvailable: boolean;

  private subscription: Subscription;

  constructor(private appStoreService: AppStoreService) {
    this.server = environment.webrender.server;
    this.parameters = environment.webrender.parameters;
    this.encoder = environment.webrender.encoder;

    const view = catalog.views[0];
    this.setView(view);

    this.error = false;
    this.loading = false;
  }

  public ngOnInit(): void {
    this.subscription = this.appStoreService.state$.subscribe(state => {
      this.interactionHelper = state.interactionHelper;
      this.design = state.design;
      this.scale = state.scale;
      this.selectedSectionId = state.selectedSectionId;

      this.scene = this.appStoreService.getScene();

      if (this.isSectionZoomed()) {
        const section = this.design.sections.find(s => s.id === this.selectedSectionId);
        const view = catalog.views.find(v => v.id === section?.view);
        this.setView(view);
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onLoadStart(): void {
    this.progress = 0;
    this.loading = true;
  }

  public onLoadProgress(progress: number): void {
    this.progress = progress;
  }

  public onLoadEnd(): void {
    this.loading = false;

    setTimeout(() => this.progress = 0, 500);
  }

  public onLoadError(): void {
    this.error = true;
    this.loading = false;
  }

  public onInteraction(): void {
    if (this.interactionHelper) {
      this.appStoreService.setInteractionHelper(false);
    }
  }

  public toggleScale(): void {
    if (this.scale) {
      this.appStoreService.setScale(false);
    } else {
      this.appStoreService.setScale(true);
    }
  }

  public isZoomOutAvailable(): boolean {
    const index = catalog.views.findIndex(v => v.id === this.view.id);
    return index > 0;
  }

  public zoomOut(): void {
    const index = catalog.views.findIndex(v => v.id === this.view.id);

    let view: View;

    if (index > 2) {
      view = catalog.views[2];
    } else {
      view = catalog.views[index - 1];
    }

    this.setView(view);
  }

  public isZoomInAvailable(): boolean {
    const index = catalog.views.findIndex(v => v.id === this.view.id);
    return index < 3;
  }

  public zoomIn(): void {
    const index = catalog.views.findIndex(v => v.id === this.view.id);

    let view: View;

    if (index === 2) {
      const section = this.design.sections.find(s => s.id === this.selectedSectionId);
      view = catalog.views.find(v => v.id === section?.view);
    } else {
      view = catalog.views[index + 1];
    }

    this.setView(view);
  }

  private isSectionZoomed(): boolean {
    const index = catalog.views.findIndex(v => v.id === this.view.id);
    return index > 2;
  }

  private setView(view: View): void {
    this.view = view;
    this.vrobject = { initPosition: this.view.initPosition };
  }
}
