import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WRAPIv2 } from '@lumiscaphe/viewer';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebRenderService {

  constructor(private readonly http: HttpClient) {
  }

  pick(data: WRAPIv2.Pick): Observable<WRAPIv2.PickResponse> {
    return this.http.post<WRAPIv2.PickResponse>(`${environment.webrender.server}/Pick`, data, { responseType: 'json' });
  }

  snapshot(data: WRAPIv2.Snapshot): Observable<WRAPIv2.SnapshotResponse> {
    return this.http.post<WRAPIv2.SnapshotResponse>(`${environment.webrender.server}/Snapshot`, data, { responseType: 'json' });
  }
}
