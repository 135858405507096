import { BehaviorSubject, Observable } from 'rxjs';

export class Store<T> {
  state$: Observable<T>;

  private stateSubject: BehaviorSubject<T>;

  protected constructor(initialState: T) {
    this.stateSubject = new BehaviorSubject(initialState);
    this.state$ = this.stateSubject.asObservable();
  }

  protected get state(): T {
    return this.stateSubject.getValue();
  }

  protected set state(nextState: T) {
    this.stateSubject.next(nextState);
  }
}
