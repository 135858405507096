import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Frame, Color, Catalog, Grain, Material, Section, Shape } from './catalog.interface';

import { AppStoreService } from './app.store.service';
import { StatsService } from './stats.service';

import { Bookmark, Design } from './app.state';

import catalog from '../assets/catalog.json';
import { GalleryComponent } from './gallery/gallery.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  catalog: Catalog;

  design: Design;
  selectedSection: Section;

  bookmarks: Bookmark[];

  private subscription: Subscription;

  constructor(private readonly appStoreService: AppStoreService, private modalService: NgbModal, private readonly statsService: StatsService) {
    this.catalog = catalog;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.updateVHProperty();

    this.subscription = this.appStoreService.state$.subscribe(state => {
      this.design = state.design;
      this.selectedSection = state.design.sections.find(s => s.id === state.selectedSectionId);

      this.bookmarks = state.bookmarks;
    });
  }

  @HostListener('window:resize') onResize(): void {
    this.updateVHProperty();
  }

  // Design

  resetDesign(): void {
    this.appStoreService.resetDesign();
  }

  async bookmarkDesign(): Promise<void> {
    await this.appStoreService.createBookmark();
    const modal = this.modalService.open(GalleryComponent, { centered: true, size: 'lg' });
    modal.componentInstance.selectedBookmark = this.bookmarks[this.bookmarks.length - 1];
  }

  openDesignGallery(): void {
    this.modalService.open(GalleryComponent, { centered: true, size: 'lg' });
  }

  // Shape

  isShapeSelected(shape: Shape): boolean {
    return this.appStoreService.isShapeSelected(shape.id);
  }

  selectShape(shape: Shape): void {
    this.appStoreService.setShape(shape);
  }

  // Section

  isSectionAvailable(): boolean {
    return this.appStoreService.isSectionAvailable();
  }

  isSectionSelected(section: Section): boolean {
    return this.appStoreService.isSectionSelected(section.id);
  }

  selectSection(section: Section): void {
    this.appStoreService.selectSection(section.id);
  }

  // Frame

  isFrameSelected(frame: Frame): boolean {
    return this.appStoreService.isFrameSelected(frame.id);
  }

  selectFrame(frame: Frame): void {
    this.appStoreService.setFrame(frame);
  }

  // Seam

  isSeamAvailable(): boolean {
    return this.appStoreService.isSeamAvailable();
  }

  isSeamSelected(seam: Frame): boolean {
    return this.appStoreService.isSeamSelected(seam.id);
  }

  selectSeam(seam: Frame): void {
    this.appStoreService.setSeam(seam);
  }

  // Piping Color

  isPipingColorAvailable(): boolean {
    return this.appStoreService.isPipingColorAvailable();
  }

  isPipingColorSelected(color: Color): boolean {
    return this.appStoreService.isPipingColorSelected(color.id);
  }

  selectPipingColor(color: Color): void {
    this.appStoreService.selectPipingColor(color);
  }

  // Stitch Color

  isStitchesColorAvailable(): boolean {
    return this.appStoreService.isStitchesColorAvailable();
  }

  isStitchesColorSelected(color: Color): boolean {
    return this.appStoreService.isStitchesColorSelected(color.id);
  }

  selectStitchesColor(color: Color): void {
    this.appStoreService.selectStitchesColor(color);
  }

  // Grain

  isGrainSelected(grain: Grain): boolean {
    return this.appStoreService.isGrainSelected(this.selectedSection.id, grain.id);
  }

  selectGrain(grain: Grain): void {
    this.appStoreService.selectGrain(this.selectedSection.id, grain);
  }

  // Color

  isColorSelected(color: Color): boolean {
    return this.appStoreService.isColorSelected(this.selectedSection.id, color.id);
  }

  selectColor(color: Color): void {
    this.appStoreService.selectColor(this.selectedSection.id, color);
  }

  // Material

  isMaterialSelected(material: Material): boolean {
    return this.appStoreService.isMaterialSelected(this.selectedSection.id, material.id);
  }

  setMaterial(material: Material): void {
    this.appStoreService.setMaterial(this.selectedSection.id, material);
  }

  // Material Crust Color

  isMaterialCrustColorAvailable(): boolean {
    return this.appStoreService.isMaterialCrustColorAvailable(this.selectedSection.id);
  }

  isMaterialCrustColorSelected(color: Color): boolean {
    return this.appStoreService.isMaterialCrustColorSelected(this.selectedSection.id, color.id);
  }

  selectMaterialCrustColor(color: Color): void {
    this.appStoreService.selectMaterialCrustColor(this.selectedSection.id, color);
  }

  isMaterialCrustColorDisable(): boolean {
    return this.appStoreService.isMaterialCrustColorDisable(this.selectedSection.id);
  }

  disableMaterialCrustColor(): void {
    this.appStoreService.disableMaterialCrustColor(this.selectedSection.id);
  }

  // Material Stitch Color

  isMaterialStitchColorAvailable(): boolean {
    return this.appStoreService.isMaterialStitchColorAvailable(this.selectedSection.id);
  }

  isMaterialStitchColorSelected(color: Color): boolean {
    return this.appStoreService.isMaterialStitchColorSelected(this.selectedSection.id, color.id);
  }

  selectMaterialStitchColor(color: Color): void {
    this.appStoreService.selectMaterialStitchColor(this.selectedSection.id, color);
  }

  isMaterialStitchColorDisable(): boolean {
    return this.appStoreService.isMaterialStitchColorDisable(this.selectedSection.id);
  }

  disableMaterialStitchColor(): void {
    this.appStoreService.disableMaterialStitchColor(this.selectedSection.id);
  }

  private updateVHProperty(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
