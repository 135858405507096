
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  sendMail(name: string, title: string, company: string, email: string, file: Blob): Promise<void> {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('title', title);
    formData.append('company', company);
    formData.append('email', email);
    formData.append('pdf', file);

    return this.http.post<void>('api/submit/index.php', formData).toPromise();
  }
}
