<div class="progress bg-transparent position-absolute w-100" style="height: 4px; z-index: 15">
  <div [class.d-none]="progress === 0" [style.width]="(progress * 100)+'%'" class="progress-bar bg-primary"></div>
</div>
<NgViewer [server]="server" api="v2" [scene]="scene" [parameters]="parameters" [view]="view" [encoder]="encoder" fit="cover" [vrobject]="vrobject" (onInteraction)="onInteraction()" (onLoadEnd)="onLoadEnd()" (onLoadError)="onLoadError()" (onLoadProgress)="onLoadProgress($event)" (onLoadStart)="onLoadStart()"></NgViewer>
<div class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center" style="pointer-events: none; z-index: 1" *ngIf="loading">
  <i class="fal fa-spinner fa-7x fa-spin text-white" style="text-shadow: 1px 1px 2px black;"></i>
</div>
<div class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-end" style="pointer-events: none; z-index: 1" *ngIf="!loading && view.mode === 'vrobject' && interactionHelper">
  <img class="my-3" src="assets/images/interaction_helper.png" width="45%">
</div>
<div class="mx-4 my-3 position-absolute" style="bottom: 0; right: 0;">
  <button type="button" class="btn btn-light mr-2" (click)="toggleScale()" title="Toggle dimensions">
    <i class="fal fa-lg fa-ruler" *ngIf="!scale"></i>
    <i class="fas fa-lg fa-ruler" *ngIf="scale"></i>
  </button>
  <button type="button" class="btn btn-light" [disabled]="!isZoomOutAvailable()" (click)="zoomOut()" title="Zoom out">
    <i class="fal fa-lg fa-search-minus"></i>
  </button>
  <button type="button" class="btn btn-light" [disabled]="!isZoomInAvailable()" (click)="zoomIn()" title="Zoom in">
    <i class="fal fa-lg fa-search-plus"></i>
  </button>
</div>